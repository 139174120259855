<template>
  <base-view icon="layers" title="Tipos de Gasto">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>

    <!-- Form -->
    <check-authorization
      :requiresAuthorizations="[permissions.create]"
      :overrideIf="$store.getters['tipoGastoModule/isEditingResource']"
      hide
    >
      <tipo-gasto-form
        ref="tipoGastoForm"
        v-show="showForm || isEditingResource"
        @hide-form="showForm = false"
        @submitted="getListadoTipoGasto"
      />
    </check-authorization>

    <!-- Listado -->
    <check-authorization :requiresAuthorizations="[permissions.list]">
      <div class="card card-header-actions">
        <div class="card-header">
          Listado de Tipos de Gasto
          <x-btn-with-auth
            v-show="!showForm"
            :requiresAuthorizations="[permissions.create]"
            :callback="cancel"
            variant="success"
            btnClass="ml-auto"
          >
            {{ !showForm ? 'Crear' : 'Ocultar' }}
          </x-btn-with-auth>
        </div>
        <div class="card-body p-3">
          <tipo-gasto-list :permissions="permissions" @input="onChangePeriodoFiscal" />
        </div>
      </div>
    </check-authorization>
  </base-view>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import TipoGastoForm from '@/components/Catalogos/TiposGasto/TipoGastoForm'
import TipoGastoList from '@/components/Catalogos/TiposGasto/TipoGastoList'

export default {
  name: 'TiposGastoView',

  components: {
    TipoGastoForm,
    TipoGastoList
  },

  computed: {
    ...mapState('tipoGastoModule', ['isCretingResource']),
    ...mapGetters('tipoGastoModule', ['isEditingResource', 'getCurrentEditing'])
  },

  created () {
    this.permissions = this.$router.currentRoute.meta.permissions
  },

  data () {
    return {
      showForm: false,
      periodo_fiscal_id: null,
      permissions: {}
    }
  },

  methods: {
    ...mapMutations('tipoGastoModule', ['setCurrentResource']),
    ...mapActions('tipoGastoModule', ['getAll']),

    async getListadoTipoGasto () {
      const { error, message } = await this.getAll(this.periodo_fiscal_id)

      if (error) this.$notify({ error, message }, 'Listado de tipos de gasto')
    },

    cancel () {
      this.showForm = !this.showForm
    },

    onChangePeriodoFiscal (periodo_fiscal_id) {
      this.periodo_fiscal_id = periodo_fiscal_id
      this.getListadoTipoGasto()
    },

    onHideForm () {
      this.showForm = false
    }
  }
}
</script>
