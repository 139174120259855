<template>
  <div>
    <!-- Componente para filtrar por el periodo fiscal activo -->
    <x-select-periodo-fiscal @input="onChangePeriodoFiscal" class="mb-3" />

    <b-table
      :fields="fields"
      :items="getList"
      striped
      bordered
      responsive
      hover
      stacked="sm"
      :busy="!isListAvailable"
    >
      <template #table-busy>
        <div class="text-center my-2" style="min-height: 25vh">
          <b-spinner class="align-middle mr-3"></b-spinner>
          <strong>Cargando...</strong>
        </div>
      </template>

      <template #cell(estatus)="row">
        <div class="cell--center">
          <b-badge :variant="$getStatusVariant(row.item.estatus)">
            {{ $getStatusText(row.item.estatus) }}
          </b-badge>
        </div>
      </template>

      <template #cell(periodo_fiscal)="row">
        <div class="cell--center">
          <b-badge>
            {{ row.item.periodo_fiscal.nombre }}
          </b-badge>
        </div>
      </template>

      <template #cell(acciones)="row">
        <div class="cell--center">
          <u-d-controls
            :id="row.item.id"
            editPath="tipoGastoModule/getToEdit"
            deletePath="tipoGastoModule/deleteById"
            :editPermissions="[permissions.update]"
            :deletePermissions="[permissions.delete]"
            @on-got-item="$up"
            @on-deleted="onDeleted"
          />
        </div>
      </template>
    </b-table>
    <div v-if="isListAvailable">
      <paginator
        aria-controls="tipoGastoTable"
        :disabled="!isListAvailable"
        show-path="tipoGastoModule/getAll"
        :resource="$store.state.tipoGastoModule.list.resource"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TipoGastoList',

  props: {
    permissions: {
      type: Object,
      required: true
    }
  },

  async created () {
    const active = await this.$getPeriodoFiscalActivo()

    this.periodo_fiscal_id = active.id
  },

  components: {
  },

  data () {
    return {
      showModal: false,
      updateData: null,
      fields: [
        { key: 'clave', label: 'Clave' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'periodo_fiscal', label: 'Periodo fiscal' },
        { key: 'estatus', label: 'Estatus' },
        { key: 'acciones', label: 'Acciones' }
      ],

      periodo_fiscal_id: null
    }
  },

  computed: {
    ...mapGetters('tipoGastoModule', ['getList', 'isListAvailable'])
  },

  methods: {
    emitOnInput () {
      this.$emit('input', this.periodo_fiscal_id)
    },
    onChangePeriodoFiscal (id) {
      this.periodo_fiscal_id = id
      this.emitOnInput()
    },
    onDeleted ({ error }) {
      if (!error) this.emitOnInput()
    }
  }
}
</script>
